.aside_retractable {
  display: flex;
  .menu_retractable {
    width: 100%;
    .barra_superior_retractable {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #eee;
      padding: 15px;

      .btn {
        margin-left: 10%;
        padding: 5px;
        color: #dedede;
        border: 1px solid #dedede;
        border-radius: 3px;
        cursor: pointer;
      }
    }
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 7px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    ::-webkit-scrollbar-track {
      background-color: #e6e6e6;
    }
    ::-webkit-scrollbar {
      width: 5px;
      background-color: #e6e6e6;
    }
    ::-webkit-scrollbar-thumb {
      background: #9d9d9d;
      border-radius: 5px;
    }

    .scroll_menu_retractable {
      position: relative;
      overflow-y: scroll;
      width: 100%;
      height: 485px;
      scroll-behavior: smooth;
      ul {
        li {
          display: flex;
          align-items: center;
          padding-bottom: 0;
          margin-bottom: 0;

          position: relative;
          height: 60px;
          padding: 0 10px 0 15px;
          cursor: pointer;
          &:after {
            content: "";
            position: absolute;
            height: 15px;
            border-left: 1px solid #0ba8bc;
            opacity: 0.2;
            width: 2px;
            left: -10px;
            top: 85%;
          }
          .link {
            position: absolute;
            height: 40px;
            border: 1px solid black;
            display: flex;
            color: #1aa8bc;
            border: 1px solid #1aa8bc;
            font-weight: bold;
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            left: -25px;
          }

          &.ativo {
            .link {
              background-color: var(--verde);
              color: #fff !important;
            }
          }

          a {
            display: inline-block;
            text-align: left;
            line-height: 1.2;
            font: normal normal 600 16px/22px Rawline;
          }
        }
      }
    }
  }
}
