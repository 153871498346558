.container {
  width: 100vw;
  height: 159px;
  background: #6c7a89;
  background-color: #00606c;
  margin-bottom: 20px;
  display: flex;
}

.container_listagem {
  width: 1100px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titulo_container {
  color: #fff;
  font-size: 32px;
}

.conteudo_principal {
  width: 600px;
}
