@import "bootstrap/dist/css/bootstrap.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.0/css/all.min.css";

:root {
  /* Colors: */
  --verde: #0ba8bc;
  --laranja: #f7a625;
  --cinza: #333333;
}
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Rawline", sans-serif;
}

body.swal2-shown{
  background-color: #0000004a;
}

.ql-editor { min-height: 150px }

body.swal2-no-backdrop .swal2-container{
  z-index: 999 !important;
  background: rgba(0, 0, 0, .4) !important;
  width: 100vw !important;
  height: 100vh !important;
  pointer-events: initial !important;
}

main {
  min-height: 50vh;
  padding-bottom: 150px;
}

input::placeholder,
::-webkit-calendar-picker-indicator {
  font-family: "Rawline", sans-serif;
  letter-spacing: 0px;
  color: #b7b7b7;
  font-weight: 100;
  color-scheme: #b7b7b7;
}

input:focus {
  outline: none;
}

a {
  text-align: right;
  color: #0ba8bc !important;
  font-weight: bold;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
  opacity: 0.8;
}

.link {
  color: #0ba8bc !important;
  font-weight: bold;
  text-decoration: none !important;
}

.link:hover {
  text-decoration: none !important;
  opacity: 0.8;
  cursor: pointer;
}

li {
  list-style: none;
}

/* Estilos dos formulários */

form {
  margin: 0 auto;
}

label {
  /*     display: flex; */
  /* flex-direction: column; */
  margin-top: 8px;
  margin-bottom: 8px;
}

label span {
  margin-bottom: 3px;
  color: #333333;
  text-align: left;
}

input {
  font-family: "Rawline", sans-serif;
  color: #0000005b !important;
  font-weight: 400;
  border: 1px solid #0000005b;
  border-radius: 4px;
  padding: 6px;
  width: 240px;
}
input::placeholder {
  font-weight: 400 !important;
  color: #000000cb !important;
}

/* Estilos Botões */
.btn {
  --bs-btn-padding-x: 2rem;
  --bs-btn-padding-y: 0.575rem;
  font-weight: bold;
  border-radius: 6px;
}

.btn-primary {
  background-color: var(--verde);
  border-color: var(--verde);
}

.btn-primary:hover {
  background-color: var(--verde);
  border-color: var(--verde);
  opacity: 0.8;
}

.btn-warning {
  background-color: var(--laranja);
  border-color: var(--laranja);
  color: #fff;
}

.btn-warning:hover {
  background-color: var(--laranja);
  border-color: var(--laranja);
  opacity: 0.8;
  color: #fff;
}

.btn-outline-primary {
  color: var(--verde);
  background-color: #fff;
  border-color: var(--verde);
}

.btn-outline-primary:hover {
  background-color: var(--verde);
  border-color: var(--verde);
  opacity: 0.8;
}

h1 {
  font-size: 1.2em;
}

.tooltip .tooltip-inner:last-child {
  background: #054851 0% 0% no-repeat padding-box;
  width: 100% !important;
  max-width: 100% !important;
  font-size: 12px;
}

.tooltip {
  width: 269px !important;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 1px !important;
}
