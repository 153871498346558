.datePiker {
  label {
    margin-top: -8px;
  }
  div {
    height: 38px !important;
  }
}

.listagem-inicial {
  background-color: transparent;
  .btn-sm {
    max-width: 98px;
    padding: 0;
    width: 100%;
    height: 100%;
    margin: 0 5px;
  }

  .table {
    th {
      color: #9a9a9a;
      font-size: 16px;
      height: 30px;
      background-color: #f4f4f4;
    }

    tr {
      border-bottom: 1px solid #dddddd;
      text-align: center;
      height: 40px;
      font-weight: bold;
      border-left: 2px solid #fff;
    }

    td > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    tr:hover {
      border-left: 2px solid #f7a625;
    }
  }
}
.container_listagem {
  width: 1100px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titulo_container {
  color: #fff;
}

.btn_cadastro {
  width: 175px;
  height: 42px;
  background-color: #f7a625;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
  font-size: 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listagem_inicial {
  width: 1100px;
  margin: 0 auto;
}

.btn_pesquisar {
  color: #fff;
  background-color: #0ba8bc;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  width: 91px;
  height: 34px;
  border: none;
  border-radius: 6px;
  font-size: 15px;
  padding: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.container_pesquisar {
  width: 1100px;
  margin: 0 auto;
  margin-top: 42px;
  position: relative;
}

.container_pesquisar form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container_pesquisar form input {
  width: 120%;
}

.container_pesquisar form input::placeholder {
  color: #b7b7b7;
}

table {
  width: 1100px;
  margin-top: 21px;
  border-collapse: collapse;
}

.btn_action_outline {
  background-color: transparent;
  border: 2px solid #1aa8bc;
  border-radius: 3px;
  color: #1aa8bc;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  padding: 3px 7px;
  margin: 5px;
  cursor: pointer;
}
