.rodape {
    background-color: #4F4F4F;
    bottom: 0;
    left: 0;
    height: 75px;
    width: 100%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    position: fixed;
    img{
       width: 120px; 
    }
    p{
        color: #fff;
        font-weight: bold;
    }
}

.footer_conteudo {
    width: 1100px;
    height: 85px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
img {
    margin: 0;

}

.footer_conteudo img {
    


}

.footer_conteudo p {
    
}