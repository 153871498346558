.header-lote {
  display: flex;
  flex-direction: row;
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;

    p {
      display: flex;
      flex-direction: column;
      font-size: x-large;
      font-weight: bold;
      span {
        font-size: medium;
        font-weight: 600;
        color: var(--verde);
      }
    }
  }
}
.formGroup {
  position: relative;

  p {
    position: absolute;
    padding-top: 4px;
    margin: 0;
    font-size: 14px;
    color: #f7412d;
  }

  input[readonly]{
    background: #ddd;
  }
}

.erroMessage {
  position: absolute;
  padding-top: 4px;
  margin: 0;
  font-size: 14px;
  color: #f7412d;
}
.editor {
  // display: flex;
  height: 150px;
  width: 100%;
}
.contadorAcao {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  border-radius: 100%;
}
.container_count_action {
  display: flex;

  flex-direction: row;
  gap: 5px;
  margin-left: 20px;
}
.container-Lote {
  .table {
    margin: 0 !important;
    tbody {
      tr {
        th {
          background-color: #d9d9d94d;
        }
      }
    }
  }
}
.TotalValueTable {
  display: flex;
  border-left-width: 2px;
  border-left-style: solid;
  border-color: rgba(217, 217, 217, 0.6);
  padding-left: 10px;
  padding: 5px;
  flex-direction: row;
  gap: 20px;

  width: 50%;
  div {
    color: rgba(132, 132, 132, 1);
    font-weight: 600;
    font-size: 14px;
  }
}
.containerPageTable {
  display: flex;
  flex-direction: row;
}
.PageTable {
  padding: 5px;
  width: 100%;
  font-weight: 600;
  color: rgba(132, 132, 132, 1);
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.checkboxForm {
  max-width: 16px;
}

.react-datepicker__day--outside-month {
  display: none;
}

.hide-outside-month {
  visibility: hidden;
}