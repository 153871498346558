/* Container */

.container_listagem {
  width: 1100px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titulo_container {
  color: #fff;
  font-size: 32px;
}

/* Conteúdo Principal */
.container__conteudo_principal {
  width: 1100px;
  margin: 0 auto;
  position: relative;
  top: 20px;
}

.container__conteudo_lateral {
  width: 720px;
}

form {
  margin-top: 42px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

label {
  display: flex;
  flex-direction: column;
}

input {
}

select {
  background-color: #fff;
  font-size: 20px;

  height: 34px;
  color: #9b9b9b;
}

.btn_principal {
  color: #fff;
  background-color: #0ba8bc;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  font-size: 1.2em;
  padding: 10px 30px;
  margin-left: 8px;
  margin-top: 10px;
}

.btn_principal__outline {
  color: #0ba8bc;
  border: 2px solid #0ba8bc !important;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  font-size: 1.2em;
  padding: 10px 30px;
  margin-right: 8px;
  margin-top: 10px;
  background-color: #fff;
}

.titulo__conteudo_lateral {
  margin-bottom: 40px;
}

.rodape__conteudo_lateral a {
  padding-top: 10px;
  position: absolute;
  left: -40px;
}

button {
  float: right;
}
/* Footer */

.footer_container {
  background-color: #4f4f4f;
  bottom: 0;
  left: 0;
  height: 85px;
  position: fixed;
  width: 100%;
}

.footer_conteudo {
  width: 1100px;
  height: 85px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

img {
  margin: 0;
}

.footer_conteudo img {
  width: 120px;
}

.footer_conteudo p {
  color: #fff;
  font-weight: bold;
}

.formGroup {
  position: relative;

  p {
    position: absolute;
    padding-top: 4px;
    margin: 0;
    font-size: 14px;
    color: #f7412d;
  }
}
.button {
  margin-top: 10px;
}
