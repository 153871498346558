.rowContainer {
  display: flex;
  flex-direction: column;

  border-bottom-style: groove;

  .textContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    div {
      display: flex;
      flex-direction: row;

      > p {
        margin: 0;
        color: #0ba8bc;
        text-align: left;
      }
    }
  }
}

.arrayAcao {
  display: flex;
  flex-direction: column;
}
.ColContainer {
  display: flex;
  flex-direction: column;

  border-bottom-style: groove;

  .textCol {
    display: inline;

    div {
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
      p {
        margin-bottom: 0;
        margin-right: 5px;
        color: #0ba8bc;
        text-align: left;
      }
    }
  }
}
.cssReset {
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  b,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    color: black;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }

  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 1px !important;
  }
}
